import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=ec91539e&scoped=true"
import script from "./Edit.vue?vue&type=script&lang=js"
export * from "./Edit.vue?vue&type=script&lang=js"
import style0 from "./Edit.vue?vue&type=style&index=0&id=ec91539e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_6m5rqjauvbyej62p7eiecas4wq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec91539e",
  null
  
)

export default component.exports