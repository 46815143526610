<template>
  <el-dialog title="课程详情页基本信息" :visible.sync="dialogVisible">
    <el-form :model="form" :rules="rules" ref="dataForm">
      <el-form-item label="活动名称" prop="name" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input v-model="form.name" placeholder="请输入活动名称"></el-input>
        </div>
      </el-form-item>
      <!-- <el-form-item label="应用课程" prop="num" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input v-model="form.num" placeholder="请输入课程id"></el-input>
        </div>
      </el-form-item> -->
      <el-form-item label="活动备注" prop="remark" :label-width="formLabelWidth">
        <div style="width: 360px">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入活动备注"></el-input>
        </div>
      </el-form-item>

      <el-form-item style="text-align: center">
        <el-button type="primary" @click="submit">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { addActivity, editActivity, copyActivity } from "@/api/course.js";

export default {
  data() {
    return {
      // mode: create, edit, copy
      mode: null,
      formLabelWidth: "135px",
      dialogVisible: false,
      form: this.initData(),
      rules: {
        name: [{ required: true, message: "请输入专题名称", trigger: "change" }],
      },
    };
  },
  methods: {
    initData() {
      return {
        name: "",
        num: "",
        remark: "",
      };
    },
    // mode: create, edit, copy
    async handleEdit(mode, v) {
      if (!["create", "edit", "copy"].includes(mode)) {
        throw new Error("请填入以下有效的 mode：create, edit, copy");
      }
      if (mode === "create") {
        this.form = this.initData();
      } else {
        this.form = v;
      }

      this.mode = mode;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
      this.dialogVisible = true;
    },
    submit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          let req = JSON.parse(JSON.stringify(this.form));
          if (this.mode === "edit") {
            await editActivity(req);
          } else if (this.mode === "create") {
            await addActivity(req);
          } else {
            await copyActivity(this.form.uuid, {
              name: this.form.name || "",
              num: this.form.num || "",
              remark: this.form.remark || "",
            });
          }
          this.$parent.fetchData();
          this.dialogVisible = false;
          this.$parent.$message({
            message: this.form.uuid ? "修改活动信息成功" : "添加活动信息成功",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/form.less");
</style>
