import axios from "@/utils/request";

// 活动列表
export function getCourseList(params) {
  return axios.get("/admin_api/v1/course", { params });
}

// 添加活动
export function addActivity(data) {
  return axios.post(`/admin_api/v1/course`, data);
}

// 编辑活动
export function editActivity(data) {
  return axios.put(`/admin_api/v1/course`, data);
}

// 活动上下架
export function toggleDisplay(id) {
  return axios.put(`/admin_api/v1/course/status/${id}`);
}

// 日志列表
export function getOperationLog(id) {
  return axios.get(`/admin_api/v1/course/${id}/log`);
}

// 复制
export function copyActivity(id, params) {
  return axios.get(`/admin_api/v1/course/copy/${id}/`, { params });
}
